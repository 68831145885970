import { frontendURL } from '../../../../helper/URLHelper';

const chat = accountId => ({
  parentNav: 'chat',
  routes: [
    'chat_layout',
  ],
  menuItems: [
    {
      icon: 'chat-multiple',
      label: 'CHAT',
      key: 'chat',
      toState: frontendURL(`accounts/${accountId}/chat`),
      toolTip: 'Chat with MetroBot',
      toStateName: 'chat_layout',
    },
  ],
});

export default chat;
