import { frontendURL } from '../../../../helper/URLHelper';
const SettingsContent = () => import('../Wrapper.vue');
const WebsitesHome = () => import('./Index.vue');

export default {
  routes: [
    {
      path: frontendURL('accounts/:accountId/settings/websites'),
      component: SettingsContent,
      props: {
        headerTitle: 'WEBSITES_MGMT.HEADER',
        icon: 'globe',
        showNewButton: false,
      },
      children: [
        {
          path: '',
          name: 'websites_wrapper',
          redirect: 'list',
        },
        {
          path: 'list',
          name: 'websites_list',
          component: WebsitesHome,
          roles: ['administrator'],
        },
        {
          path: 'detail/:websiteId',
          name: 'websites_detail',
          component: WebsitesHome,
          roles: ['administrator'],
        }
      ],
    },
  ],
};
