import ChatLayout from './components/ChatLayout.vue';
import { frontendURL } from '../../../helper/URLHelper';

export default {
  routes: [
    {
      path: frontendURL(`accounts/:accountId/chat`),
      name: 'chat_layout',
      roles: ['administrator', 'agent'],
      component: ChatLayout,
      children: [],
    },
  ],
};
